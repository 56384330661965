import React, { useEffect } from "react";
import SEO from "src/components/seo.js";

const CovidFund = () => {
    useEffect(() => {
        window.location.href = "https://pages.sumac.com/sumac/sumac_start_new_session.php?package=donation2&userfolder=../ORG/torontoartsfoundation";
    }, []);
    return <SEO title="HXOUSE · Covid Fund" />
};

export default CovidFund;
